import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { Container, Form, Button, Toast, Card, Badge, ListGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';

interface AnalysisResult {
  code: string;
  results: {
    [key: string]: string;
  };
}

const apiUrl = process.env.REACT_APP_API_URL || '';

function App() {
  const [snippet, setSnippet] = useState('');
  const [result, setResult] = useState<AnalysisResult | null>(null);
  const [error, setError] = useState('');
  const [showToast, setShowToast] = useState(false);

  const handleAnalyze = async (codeToAnalyze: string) => {
    setError('');
    setResult(null);
    setShowToast(false);

    const detectUrl = `${apiUrl}/detect`;
    try {
      const response = await axios.post(detectUrl, { snippet: codeToAnalyze });
      setResult(response.data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          // Extract a meaningful error message from the response
          const errorMessage = err.response.data.message || err.response.statusText || 'Unknown error occurred';
          setError(`Server error: ${err.response.status} - ${errorMessage}. URL: ${detectUrl}`);
        } else if (err.request) {
          // no response
          setError(`Unable to connect to the backend at ${detectUrl}. Is the server running?`);
        } else {
          setError(`Error: ${err.message}. URL: ${detectUrl}`);
        }
      } else {
        setError(`An unexpected error occurred while processing the request to ${detectUrl}.`);
      }
      setShowToast(true);
      console.error(err);
    }
  };

  useEffect(() => { // Load snippet from URL
    const urlParams = new URLSearchParams(window.location.search);
    const encodedCode = urlParams.get('code');
    if (encodedCode) {
      try {
        const decodedCode = Base64.decode(encodedCode);
        setSnippet(decodedCode);
        handleAnalyze(decodedCode);
      } catch (error) {
        console.error('Error decoding URL parameter:', error);
        setError('Invalid URL parameter');
        setShowToast(true);
      }
    }
  }, []);

  useEffect(() => { // Ctrl/Cmd+Enter to Analyze
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        handleAnalyze(snippet);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [snippet]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleAnalyze(snippet);
  };

  const handleShare = () => { // Share snippet
    if (snippet.trim() === '') return;

    const encodedSnippet = Base64.encodeURI(snippet);
    const shareUrl = `${window.location.origin}${window.location.pathname}?code=${encodedSnippet}`;

    // Copy to clipboard
    navigator.clipboard.writeText(shareUrl).then(() => {
      // Update browser URL
      window.history.pushState({}, '', shareUrl);

      setError('Link copied to clipboard!');
      setShowToast(true);
    }).catch((err) => {
      console.error('Failed to copy link:', err);
      setError('Failed to copy link');
      setShowToast(true);
    });
  };

  // NL vs Bash, same as baseline/grazie_api_nl2bash.py#enry_shell_vs_all()
  const enry_shell_vs_all = (language: string) => {
    if (language === 'shell' || language === 'powershell') {
      return 'code';
    }
    return 'nl';
  };

  // NL vs Unknown, same as baseline/grazie_api_nl2bash.py#langd_ukn_vs_all()
  const langd_ukn_vs_all = (language: string) => {
    if (language === '-' || language === 'unknown') {
      return 'code';
    }
    return 'nl';
  };

  return (
    <Container className="mt-5">
      <h1>Programming Language Identification</h1>
      <Card className="mb-4">
        <Card.Body>
          <Card.Title>About JetEnry</Card.Title>
          <Card.Text>
            <a href="https://jetbrains.team/p/ai-assistant/repositories/jet-enry/files/README.md" target="_blank" rel="noopener noreferrer">
              JetEnry
            </a> is a library for programming language identification (PLid) in JVM.
            It is a descendent on the Go library <a href="https://github.com/go-enry/go-enry" target="_blank" rel="noopener noreferrer">Enry</a>.
          </Card.Text>
          <Card.Text>
            Enter a code snippet below and the tool will attempt to:
          </Card.Text>
          <ul>
            <li>Identify the programming language using a small <a href='https://jetbrains.team/p/ai-assistant/repositories/jet-enry/files/java/library/README.md'>trained model</a></li>
            <li>Compare the result with the NLP LanguageDetector</li>
          </ul>
        </Card.Body>
      </Card>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Enter code snippet:</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={snippet}
            onChange={(e) => setSnippet(e.target.value)}
            placeholder="Paste your code snippet here..."
          />
        </Form.Group>
        <div className="d-flex justify-content-between">
          <Button variant="primary" type="submit">
            Analyze <i className="bi bi-command ms-1 me-1 button-icon"></i><i className="bi bi-arrow-return-left button-icon"></i>
          </Button>
          <Button
            variant="light"
            onClick={handleShare}
            disabled={snippet.trim() === ''}
          >
            <i className="bi bi-share me-2"></i>
            Share
          </Button>
        </div>
      </Form>
      {result && (
        <Card className="mt-4">
          <Card.Header>
            <h3>Result</h3>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              {/* Column Headers */}
              <ListGroup.Item className="d-flex align-items-center justify-content-end fw-bold">
                <div className="col-6">Library</div>
                <div className="col-4 text-end">Detected Language</div>
                <div className="col-2 text-end">NL vs Bash</div>
              </ListGroup.Item>

              {Object.entries(result.results).map(([detector, language]) => {
                let languageClass; let tooltipText;
                if (detector.startsWith('jet-enry')) {
                  languageClass = enry_shell_vs_all(language); // Get the class based on language
                  tooltipText = 'Code when it is "Shell" or "Powershell" and NL otherwise.'
                } else {
                  languageClass = langd_ukn_vs_all(language);
                  tooltipText = 'Code when it is an "Unknown" language and NL otherwise.'
                }
                return (
                  <ListGroup.Item key={detector} className="d-flex justify-content-between align-items-center">
                    <div className="col-6 d-flex align-items-center">
                      <span className="me-2">{detector}</span> {/* Detector name */}
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                      <span className="text-muted me-2">{language}</span> {/* Language column */}
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-end">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-${detector}`}>{tooltipText}</Tooltip>}
                      >
                        <Badge
                          bg={languageClass === 'nl' ? 'secondary' : 'primary'}
                          pill
                        >
                          {languageClass} {/* Classification column */}
                        </Badge>
                      </OverlayTrigger>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Card.Body>
        </Card>
      )}
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={10000}
        autohide
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
        }}
      >
        <Toast.Header>
          <strong className="mr-auto">{error.startsWith('Link copied') ? 'Success' : 'Error'}</strong>
        </Toast.Header>
        <Toast.Body>{error}</Toast.Body>
      </Toast>
    </Container>
  );
}

export default App;
